  #xalert-preloader-container{
    background: black;
    position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10000;
  }
  
  #xalert-preloader-container svg{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width: 60vw;
    height: 60vh;
  }
  
  #gLettere {
    fill: url(#linearGradientBLUE);
    stroke: white;
  }
  
  $len_X: 223.95211791992188;
  $len_DASH: 41.61199188232422;
  $len_TopRight: 74.5941162109375;
  $len_TopLeft: 86.34806823730469;
  $len_BottomRight: 48.88639831542969;
  $len_BottomLeft: 48.88494873046875;
  $len_L: 153.6019744873047;
  $len_E: 248.41604614257812;
  $len_R: 236.39344787597656;
  $len_T: 165.95797729492188;
  
  $refspeed: $len_X / .9s;
  
  @function drawLast($len) {
      @return $len / $refspeed;
  }
  
  $letterDrawLasts: ();
  $letterDrawLasts: append($letterDrawLasts, drawLast($len_X));
  $letterDrawLasts: append($letterDrawLasts, drawLast($len_DASH));
  $letterDrawLasts: append($letterDrawLasts, drawLast($len_TopRight)+drawLast($len_TopLeft)+drawLast($len_BottomRight)+drawLast($len_BottomLeft));
  $letterDrawLasts: append($letterDrawLasts, drawLast($len_L));
  $letterDrawLasts: append($letterDrawLasts, drawLast($len_E));
  $letterDrawLasts: append($letterDrawLasts, drawLast($len_R));
  $letterDrawLasts: append($letterDrawLasts, drawLast($len_T));
  
  $startDelay: 1s;
  $timeLasts: ();
  $timeLasts: append($timeLasts, $startDelay);    // X
  $timeLasts: append($timeLasts, nth($letterDrawLasts, 1) + (nth($timeLasts, 1) * 0.7) ); // -
  $timeLasts: append($timeLasts, nth($letterDrawLasts, 2) + (nth($timeLasts, 2) * 0.7) ); // logo
  $timeLasts: append($timeLasts, nth($letterDrawLasts, 3) + (nth($timeLasts, 3) * 0.7) ); // L
  $timeLasts: append($timeLasts, nth($letterDrawLasts, 4) + (nth($timeLasts, 4) * 0.7) ); // E
  $timeLasts: append($timeLasts, nth($letterDrawLasts, 5) + (nth($timeLasts, 5) * 0.7) ); // R
  $timeLasts: append($timeLasts, nth($letterDrawLasts, 6) + (nth($timeLasts, 6) * 0.7) ); // T
  
  #path_X { stroke-dasharray: $len_X;stroke-dashoffset: $len_X; animation: line-anim linear nth($letterDrawLasts, 1) forwards nth($timeLasts, 1) };
  #path_DASH { stroke-dasharray: $len_DASH;stroke-dashoffset: $len_DASH; animation: line-anim linear nth($letterDrawLasts, 2) forwards nth($timeLasts, 2) };
  #path_TopRight { stroke-dasharray: $len_TopRight;stroke-dashoffset: $len_TopRight; animation: line-anim linear nth($letterDrawLasts, 3) forwards nth($timeLasts, 3) };
  #path_TopLeft { stroke-dasharray: $len_TopLeft;stroke-dashoffset: $len_TopLeft; animation: line-anim linear nth($letterDrawLasts, 3) forwards nth($timeLasts, 3) };
  #path_BottomRight { stroke-dasharray: $len_BottomRight;stroke-dashoffset: $len_BottomRight; animation: line-anim linear nth($letterDrawLasts, 3) forwards nth($timeLasts,3) };
  #path_BottomLeft { stroke-dasharray: $len_BottomLeft;stroke-dashoffset: $len_BottomLeft; animation: line-anim linear nth($letterDrawLasts, 3) forwards nth($timeLasts, 3) };
  #path_L { stroke-dasharray: $len_L;stroke-dashoffset: $len_L; animation: line-anim linear nth($letterDrawLasts, 4) forwards nth($timeLasts, 4) };
  #path_E { stroke-dasharray: $len_E;stroke-dashoffset: $len_E; animation: line-anim linear nth($letterDrawLasts, 5) forwards nth($timeLasts, 5) };
  #path_R { stroke-dasharray: $len_R;stroke-dashoffset: $len_R; animation: line-anim linear nth($letterDrawLasts, 6) forwards nth($timeLasts, 6) };
  #path_T { stroke-dasharray: $len_T;stroke-dashoffset: $len_T; animation: line-anim linear nth($letterDrawLasts, 7) forwards nth($timeLasts, 7) };
  
  $startFadeoffStroke: nth($timeLasts, 7) * 1.2;
  $startFillBlue: $startFadeoffStroke * 1.2;
  $startBackgToWhite: $startFillBlue * 1.2;
  #gLettere {animation: hide-stroke ease 3s forwards $startFadeoffStroke}
  #stop_blue_bottom{animation: fill-blue ease 3s forwards $startFillBlue}
  #stop_blue_middle{animation: fill-lightblue ease 3s forwards $startFillBlue}
  #stop_blue_top{animation: fill-lightblue ease 3s forwards $startFillBlue}
  #xalert-preloader-container{animation: back-to-white ease 3s forwards $startBackgToWhite}
  
  @keyframes line-anim      { to  { stroke-dashoffset: 0; } }
  @keyframes hide-stroke    { from{ stroke: white; }  to{ stroke: transparent;} }
  @keyframes back-to-white  { from{ background: black; }  to{ background: white;  }}
  @keyframes fill-lightblue { from{ stop-opacity: 0; } to{ stop-opacity: 1; }}
  @keyframes fill-blue      { from{ stop-opacity: 0; } to{ stop-opacity: 1; }}
  
  /* -----------------------------------------------------------
     Progress
     ----------------------------------------------------------- */

     progress {
      -webkit-transition: opacity 0.4s;
         -moz-transition: opacity 0.4s;
          -ms-transition: opacity 0.4s;
              transition: opacity 0.4s;
    }

    div#progressLoaderContainer
    {
      position: absolute;
      bottom: 1rem;
      width: 50%;
      height: 0px;
      border: none;
      border-bottom: gray solid 1px;
      width: 95%;
      transform: translate(2.5%);
    }

    div#progressLoader
    {
      position: absolute;
      height: 100%;
      // top: -1px;
      // left: -1px;
      width: 100%;
      // border: 1px solid red;
      border-bottom: 1px solid $blue;
      // animation: progress-value-color ease 3s forwards $startBackgToWhite
    }

    #pShine{
      opacity: 0;
      position: absolute;
      height: 0px;
      width: 0px;
      bottom: -1px;
      border: 1px solid $lightblue;
      box-shadow:0px 0px 7px 5px $blue;
      // border-bottom: 1px solid red;
  }

    @keyframes progress-container-color  { from{ border-bottom: 1px solid $body-color; }  to{ border-bottom: 1px solid transparent;  }}
    @keyframes progress-value-color  { from{ border-bottom: 1px solid $testoHomePage; }  to{ border-bottom: 1px solid $lightblue;  }}
