// 
// 
// user.scss
//
// Place your own sparrow CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.
body {
    font-size: 1.1rem;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: initial; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.container-fluid .row {
    margin-right: auto;
    margin-left: auto;
}

#gridNav{
    padding: 0;
}

.areaContent {
    p{
    text-align: justify;
    }

    a {
        text-decoration: underline;
        color: darken($body-color, 5);
    }

    li{
        border: none;
    }
}

#faq {
    font-style: italic;

    p.question{
        font-style: normal;
        font-weight: bold;
    }
}

b,
strong {
    font-family: 'Aileron-Heavy';
    // font-weight: bolder;
}

a.copyright,
a.copyright:hover {
    color: white;
    text-decoration: none;
    background-color: transparent;
}

.sidebar-item-wrapper {
    text-transform: uppercase;
}

.gotoAnchorItem{
    cursor: pointer;
}

#treColonnePageXAlert{
    color: $lightblue;
    font-weight: bold;
    font-size: 1.2rem;
    white-space: nowrap;
    & .primaCol .result{
        color: $alert;
    }
    & .secondaCol .result{
        color: $demo;
    }
    & .terzaCol .result{
        color: $contact;
    }
}

#xAlertPageAccordions h4
{
    font-style: italic;
    color: $lightblue;
}

//
// Headings (_type.scss)
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: map-get($spacers , 4 );
}

.accordion{
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      display: block;
    }   
}

div.ui.accordion {
    i ~ h5{
        display: inline;
    }

    i{
        color: $lightblue;
    }
}


.page-title{
    text-transform: uppercase;
}

.text-project{color: $project;}
.text-alert{color: $alert;}
.text-demo{color: $demo;}
.text-company{color: $company;}
.text-contact{color: $contact;}
.text-faq{color: $faq;}
.text-blue{color: $blue;}
.text-lightblue{color: $lightblue;}
.text-homepage{color: $testoHomePage;}
.font-overVideo{
        // background-color: transparent;
        // font-size: 3rem;
        font-family: 'Aileron-Black';
        // text-align: center;
        // padding: 1rem;
}

/*-----------------------------------------------
|   Accordions
-----------------------------------------------*/

/* ------- Stilizzare Accordion Card ------------- */

.card {
    background-color: transparent;
    text-align: justify;
    border: 1px solid #afafaf;
}

.card-header {
    background-color: #ffffff0a;
}

.hangingIndent p {
    text-indent: -28px;
    padding-left: 28px;
}

/* ------------ accordions ----------------- */

.accordion .card {
    border: none;
    padding: 0;
}

.accordion .card-header {
    background-color: transparent;
    padding-left: 0rem;
    padding-bottom: 0.4rem;
    border-bottom: none;
}

.card-body > *:first-child{
    padding-top: 1rem;
}

.card-header h5 {
    text-align: left;
    cursor: pointer;
}

.accordion .card-body {
    padding: 0 0;
}

.accordion .card-header:before {
    content: "-";
    float: left;
    cursor: pointer;
    padding-right: 0.2rem;
    font-family: $headings-font-family;
    color: $headings-color;
    font-weight: $headings-font-weight;
    font-size: $h5-font-size;
}

.accordion .card-header.collapsed:before {
    content: "+";
    cursor: pointer;
    padding-right: 0.2rem;
    font-family: $headings-font-family;
    color: $headings-color;
    font-weight: $headings-font-weight;
    font-size: $h5-font-size;
}

.pdfDownloadIcon {
    max-width: 32px;
}

/* ------------- VIDEO ------------- */

#homepageVideo {
    z-index: 0;
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
}

.videoColorMap {
    z-index: 1;
    position: absolute;
    // background-color: white;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

#buttonLinkHomePageWrapper {

    font-family: 'Aileron-Regular';
    font-size: 1.6rem;
    color: hsla(218, 92%, 15%, 1);
    background-color: rgb(241, 241, 241);
    // border-radius: 3rem;
    padding: 0.5rem 2rem;
    transition: all .3s;

    border-color: $alert;
    animation: button-anim-pulse ease-in-out 1.8s;
    animation-iteration-count: infinite;

    &:hover{
        animation: button-anim-pulse-blu ease-in-out 1.8s;
        animation-iteration-count: infinite;
        border: 1px solid $lightblue;
       }

    &:focus {
        box-shadow: none;
    }

    span{
        font-family: 'Aileron-Black';
    }
}

@keyframes button-anim-pulse {
    0% {
        box-shadow: $alert 0px 0px 5px 0px;
    }
    50% {
        box-shadow: $alert 0px 0px 10px 5px;
    }
    100% {
        box-shadow: $alert 0px 0px 5px 0px;
    }
}

@keyframes button-anim-pulse-blu {
    0% {
        box-shadow: $lightblue 0px 0px 5px 0px;
    }
    50% {
        box-shadow: $lightblue 0px 0px 10px 5px;
    }
    100% {
        box-shadow: $lightblue 0px 0px 5px 0px;
    }
}

/* ------------- PRELOADER ------------- */

#preloader {
    background-color: white;
}

.loader {
    span {
        background: $alert;
        &:nth-child(1) {
            background: $alert;
        }
        &:nth-child(2) {
            background: $demo;
        }
        &:nth-child(3) {
            background: $company;
        }
        &:nth-child(4) {
            background: $contact;
        }
    }
}

/*-----------------------------------------------
|   Demo Video
-----------------------------------------------*/

#newSpotMark{
    vertical-align: middle;
    background-image: url('../images/x-Alert Logo Circled Green.svg'), url('../images/mapBckground.jpg');
    background-repeat: no-repeat,no-repeat;
    background-size:  5rem, auto;
    background-position-x: 50%,0;
    background-position-y: 50%,0;
    min-height: 12rem;
}

#newSpotAlert{
    vertical-align: middle;
    background-image: url('../images/x-Alert Logo Circled Red scuro.svg'), url('../images/mapBckground.jpg');
    background-repeat: no-repeat,no-repeat;
    background-size:  5rem, auto;
    background-position-x: 50%,0;
    background-position-y: 50%,0;
    min-height: 12rem;
}

#newSpotMarkGallery{
    vertical-align: middle;
    background-image: url('../images/spotGallery_green.svg'), 
                    url('../images/mapBckground.jpg');
    background-repeat: no-repeat;
    background-size: 6rem, auto;
    background-position-x: 50%,0;
    background-position-y: 50%,0;
    min-height: 12rem;
}

#newAlertMarkGallery{
    vertical-align: middle;
    background-image: url('../images/spotGallery_red.svg'), 
    url('../images/mapBckground.jpg');
    background-repeat: no-repeat;
    background-size: 6rem, auto;
    background-position-x: 50%, 0;
    background-position-y: 50%, 0;
    min-height: 12rem;
}

#newBookmarkSpot{
    vertical-align: middle;
    background-image: url('../images/spot_newfavorites.svg'), 
    url('../images/mapBckground.jpg');
    background-repeat: no-repeat;
    background-size: 5rem, auto;
    background-position-x: 50%,0;
    background-position-y: 50%,0;
    min-height: 12rem;
}

#editBookmarkSpot{
    vertical-align: middle;
    background-image: url('../images/edit_favorites.svg'), 
    url('../images/mapBckground.jpg');
    background-repeat: no-repeat;
    background-size: 5rem, auto;
    background-position-x: 50%,0;
    background-position-y: 50%,0;
    min-height: 12rem;
}

#demo a{
    text-decoration: none;
}

/*-----------------------------------------------
|   Demo Carousel
-----------------------------------------------*/

.owl-carousel .owl-nav{
    overflow: hidden;
    height: 0px;
  }

.owl-theme .owl-nav [class*=owl-]{
    opacity: 1;
    width: 3rem;
    height: 100%;
    border-radius: 0;
    box-shadow: none;
    outline: none;
}

.owl-theme .owl-nav .owl-prev {
    left: -3rem;
}

.owl-theme .owl-nav .owl-next {
    right: -3rem;
}

.owl-carousel .nav-btn{
      height: 2rem;
      width: 1rem;
      cursor: pointer;
      outline: none!important;
  }

  .owl-carousel .owl-prev.disabled,
  .owl-carousel .owl-next.disabled{
    pointer-events: none;
    opacity: 0.2;
  }