//
//
// user-variables.scss
//
// Place your own variable overrides here, these will override any Bootstrap and theme variables.
//*-----------------------------------------------
//|   Fonts Families
//-----------------------------------------------*/
@font-face {
    font-family: 'Aileron-UltraLight';
    src: url("../fonts/Aileron-UltraLight.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-Thin';
    src: url("../fonts/Aileron-Thin.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-Light';
    src: url("../fonts/Aileron-Light.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-Regular';
    src: url("../fonts/Aileron-Regular.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-SemiBold';
    src: url("../fonts/Aileron-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-Bold';
    src: url("../fonts/Aileron-Bold.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-Heavy';
    src: url("../fonts/Aileron-Heavy.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-Black';
    src: url("../fonts/Aileron-Black.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-UltraLightItalic';
    src: url("../fonts/Aileron-UltraLightItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-ThinItalic';
    src: url("../fonts/Aileron-ThinItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-LightItalic';
    src: url("../fonts/Aileron-LightItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-SemiBoldItalic';
    src: url("../fonts/Aileron-SemiBoldItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-Italic';
    src: url("../fonts/Aileron-Italic.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-BoldItalic';
    src: url("../fonts/Aileron-BoldItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-HeavyItalic';
    src: url("../fonts/Aileron-HeavyItalic.otf") format("opentype");
}

@font-face {
    font-family: 'Aileron-BlackItalic';
    src: url("../fonts/Aileron-BlackItalic.otf") format("opentype");
}

//*-----------------------------------------------
//|   Fonts Families
//-----------------------------------------------*/
$font-family-sans-serif: 'Aileron-Regular',Raleway,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !default;
$font-family-serif: Georgia,serif !default;
//$font-family-monospace: 'PT Mono', SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: 'Aileron-Regular','Source Sans Pro',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !default;

/* ======================================================
    Colori Istituzionali
/* ====================================================== */

$blue: #093eb7;
$lightblue: #0a5be5;
$alert: #fd0200;
$demo: #02a645;
$company: #0065b7;
$contact: #feb801;
$faq: #333334;
$project: #aeaaaa;
$testoHomePage: #f1f1f1;

$body-color:  #626261;

/* ======================================================
    Headings - Titoli
/* ====================================================== */

$headings-color: $lightblue;
$headings-font-family: 'Aileron-Black';
$headings-font-weight: 900;
$font-size-base: 1rem;
$line-height-base: 1.45;
$h4-font-size: $font-size-base * 1.493 !default;

